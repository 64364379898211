import { CrudService } from "./crudService";
import {
  CassetteResource,
  DabResource,
  TicketCategorieResource,
  TicketResource,
  TicketTypeResource,
} from "types/ssb.type";
import { requestDelete, requestPost, requestPut } from "./request";
import { exportService } from "./exportService";
import { RequestParam } from "types";

const cassette = new CrudService<CassetteResource, CassetteResource>(
  "cassettes"
);

export const cassetteService = {
  ...cassette,
};

export const dab = new CrudService<DabResource, DabResource>("dabs");

export const dabService = {
  ...dab,
};

export const ticket = new CrudService<TicketResource, TicketResource>(
  "tickets"
);

export const ticketService = {
  ...ticket,

  affecterEquipage: (ticketId: string, body: Record<string, any>) => {
    return requestPut<{ data: TicketResource }>(
      `${ticket.getBase()}/${ticketId}/affecter`,
      body
    ).then((r) => r.data);
  },

  debuterIntervention: (ticketId: string, body: Record<string, any>) => {
    return requestPut<{ data: TicketResource }>(
      `${ticket.getBase()}/${ticketId}/debuterIntervention`,
      body
    ).then((r) => r.data);
  },

  finaliserIntervention: (ticketId: string, body: Record<string, any>) => {
    return requestPut<{ data: TicketResource }>(
      `${ticket.getBase()}/${ticketId}/finaliserIntervention`,
      body
    ).then((r) => r.data);
  },

  indiquerArrivee: (ticketId: string, body: Record<string, any>) => {
    return requestPut<{ data: TicketResource }>(
      `${ticket.getBase()}/${ticketId}/indiquerArrivee`,
      body
    ).then((r) => r.data);
  },

  cloturer: (ticketId: string) => {
    return requestPut<{ data: TicketResource }>(
      `${ticket.getBase()}/${ticketId}/cloturer`
    ).then((r) => r.data);
  },

  generer: (body: any) => {
    return requestPut<{ data: TicketResource }>(
      `${ticket.getBase()}/generate`,
      body
    ).then((r) => r.data);
  },

  addObservation: (ticketId: string, body: Record<string, any>) => {
    return requestPost<{ data: TicketResource }>(
      `${ticket.getBase()}/${ticketId}/observations`,
      body
    ).then((r) => r.data);
  },

  bordereau: (ticketId: string) => {
    return exportService.pdf(`tickets/${ticketId}/bordereau`);
  },

  deleteTicketsNonTraitees: (body: RequestParam) => {
    return requestDelete(ticket.getBase(), body);
  },
};

export const ticketCategorie = new CrudService<
  TicketCategorieResource,
  TicketCategorieResource
>("ticketCategories");

export const ticketCategorieService = {
  ...ticketCategorie,
};

export const ticketType = new CrudService<
  TicketTypeResource,
  TicketTypeResource
>("ticketTypes");

export const ticketTypeService = {
  ...ticketType,
};
