import { FC } from "react";
import { ParametrageResource } from "types";
import MainCard from "components/MainCard";
import { Collapse } from "components";
import { List, Receipt } from "@mui/icons-material";
import { ViewItem2 as ViewItem, ViewList, ViewListItem } from "pages/common";
import { Divider, Grid } from "@mui/material";
import { format } from "date-fns";

type SettingFactureDetailProps = {
  parametrage: ParametrageResource;
};

type AficherNumeroExempleProps = {
  numeroPrefixe?: string;
  numeroInitial?: string;
  longueurNumeroFormat?: string;
  numeroFormatAnnee?: string;
};

export const AficherNumeroExemple: FC<AficherNumeroExempleProps> = (props) => {
  const { numeroComplet, numeroSuffixe } = calculerNumeroExemple(props);

  return (
    <div>
      <p>
        <span>Le numéro calculé est sous la forme : </span>
        <b>PrefixeAnneeSuffixe</b>
      </p>

      <ul>
        <li>Préfixe : {props.numeroPrefixe}</li>
        <li>Année : {props.numeroFormatAnnee}</li>
        <li>Suffixe : {numeroSuffixe}</li>
      </ul>
      {/* <span>Exemple de numéro : </span> */}

      <span>
        Exemple complet : <b>{numeroComplet}</b>
      </span>
    </div>
  );
};

const calculerNumeroExemple = ({
  numeroPrefixe,
  numeroInitial,
  longueurNumeroFormat,
  numeroFormatAnnee,
}: AficherNumeroExempleProps) => {
  const annee = numeroFormatAnnee ? format(new Date(), numeroFormatAnnee) : "";

  const numeroSuffixe = numeroInitial?.padStart(
    parseInt(longueurNumeroFormat || "0"),
    "0"
  );

  const numeroComplet = `${numeroPrefixe || ""}${annee}${numeroSuffixe || ""}`;

  return { numeroComplet, numeroSuffixe };
};

const SettingFactureDetail: FC<SettingFactureDetailProps> = ({
  parametrage,
}) => {
  const { value } = parametrage;
  return (
    <>
      <MainCard sx={{ marginBottom: 3 }}>
        <Collapse
          title="Facture"
          subtitle="Paramètre général de la facture"
          leftIcon={<Receipt />}
        >
          <ViewList>
            <ViewListItem>
              <Grid item xs={12} sm={4}>
                <ViewItem label="TVA">
                  {value.tva ? `${value.tva}%` : null}
                </ViewItem>
              </Grid>
              <Grid item xs={12} sm={4}>
                <ViewItem label="Mode règlement">
                  {value?.modeReglements?.join(" , ")}
                </ViewItem>
              </Grid>
              <Grid item xs={12} sm={4}>
                <ViewItem label="Entête pre imprimée">
                  {value.entetePreImprimee === "oui" ? "Oui" : "Non"}
                </ViewItem>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} md={4}>
                <ViewItem label="Payer à l'ordre de">{value?.porteur}</ViewItem>
              </Grid>

              <Grid item xs={12} md={4}>
                <ViewItem label="Condition paiement">
                  {value?.conditionPaiement}
                </ViewItem>
              </Grid>

              <Grid item xs={12} md={4}>
                <ViewItem label="Directeur générale délégué">
                  {value?.directeurGeneralDelegue}
                </ViewItem>
              </Grid>
            </ViewListItem>
          </ViewList>
        </Collapse>
      </MainCard>

      <MainCard sx={{ marginBottom: 3 }}>
        <Collapse
          title="Numéro de facture"
          subtitle="Paramètre format du numéro de facture"
          leftIcon={<List />}
        >
          <ViewList>
            <ViewListItem>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={3}>
                    <ViewItem label="Prefixe">{value?.numeroPrefixe}</ViewItem>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <ViewItem label="Numéro format année">
                      {value?.numeroFormatAnnee?.label}
                    </ViewItem>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <ViewItem label="Longueur format numérique">
                      {value?.longueurNumeroFormat}
                    </ViewItem>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <ViewItem label="Numéro initial">
                      {value?.numeroInitial}
                    </ViewItem>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <ViewItem label="Exemple de numéro">
                      {
                        calculerNumeroExemple({
                          longueurNumeroFormat: value?.longueurNumeroFormat,
                          numeroInitial: value?.numeroInitial,
                          numeroFormatAnnee: value?.numeroFormatAnnee?.value,
                          numeroPrefixe: value?.numeroPrefixe,
                        }).numeroComplet
                      }
                    </ViewItem>
                  </Grid>
                </Grid>
              </Grid>
            </ViewListItem>
          </ViewList>
        </Collapse>
      </MainCard>

      <MainCard sx={{ marginBottom: 3 }}>
        <Collapse
          title="Détail facture"
          subtitle="Paramètre des détails de la facture"
          leftIcon={<List />}
        >
          <ViewList>
            <ViewListItem>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <ViewItem label="Raison sociale">
                      {value?.raisonSociale}
                    </ViewItem>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ViewItem label="Siège social">
                      {value?.siegeSocial}
                    </ViewItem>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} md={4}>
                <ViewItem label="Adresse postale">{value?.adresse}</ViewItem>
              </Grid>
              <Grid item xs={12} md={4}>
                <ViewItem label="Numéro téléphone">{value?.telephone}</ViewItem>
              </Grid>
              <Grid item xs={12} md={4}>
                <ViewItem label="CNC/IFU/NIF/NCC">{value?.ncc}</ViewItem>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <ViewItem label="Imprimer par">
                  {value?.imprimeur?.mode === "personne_connectee"
                    ? "Personne connectée"
                    : value?.imprimeur?.nom}
                </ViewItem>
              </Grid>
            </ViewListItem>
          </ViewList>
        </Collapse>
      </MainCard>
    </>
  );
};

export default SettingFactureDetail;
